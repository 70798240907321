/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import Wrapper from '../components/Wrapper'
import Title from '../components/Title'
import Text from '../components/Text'

const TextContainer = styled.div`
  ${({ theme }) => css`
    margin: 50px auto;
    width: 75%;

    ${theme.queries.small} {
      width: 100%;
      margin: 50px 0;
      padding: 0 10px;
    }
  `}
`

const SimpleText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.arsenic};
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px !important;
  `}
`

const Termos = () => {
  const intl = useIntl()

  return (
    <Layout>
      <Wrapper>
        <TextContainer>
          <Title
            align="center"
            size="40"
            css={css`
              margin-bottom: 40px;
            `}
          >
            {intl.formatMessage({ id: 'pages.policy.title' })}
          </Title>

          <SimpleText weight="500">
            {intl.formatMessage({ id: 'pages.policy.text_1' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_2' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_3' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_4' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_5' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_6' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_7' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_8' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_9' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_10' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_11' })}
          </SimpleText>

          <SimpleText weight="500">
            {intl.formatMessage({ id: 'pages.policy.text_12' })}
          </SimpleText>

          <SimpleText>
            {intl.formatMessage({ id: 'pages.policy.text_13' })}
          </SimpleText>
        </TextContainer>
      </Wrapper>
    </Layout>
  )
}

export default Termos
