import { css } from '@emotion/core'
import styled from '@emotion/styled'

const STYLE_MAP = {
  h1: { size: 52 },
  h2: { size: 48 },
  h3: { size: 34 },
  h4: { size: 24 },
}

const Title = styled.h2`
  ${({
    theme,
    color = 'jet',
    as = 'h2',
    align = 'left',
    lineHeight = 60,
    marginBottom = 0,
    mobileSize,
    mobileHeight,
    mobileAlign,
    size,
  }) => css`
    color: ${theme.colors[color]};
    font-size: ${STYLE_MAP[as].size}px;
    ${size && `font-size: ${size}px`};
    font-family: 'Above', sans-serif;
    font-weight: normal;
    ${marginBottom && `margin-bottom: ${marginBottom}px`};
    ${lineHeight && `line-height: ${lineHeight}px`};
    text-align: ${align};

    ${(mobileSize || mobileHeight || mobileAlign) &&
      `
      ${theme.queries.small} {
        ${mobileSize && `font-size: ${mobileSize}px`};
        ${mobileHeight && `line-height: ${mobileHeight}px`};
        ${mobileAlign && `text-align: ${mobileAlign}`};
      }
    `}
  `};
`

export default Title
